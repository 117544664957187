import { Currency } from 'types/graphql'

export const globalConfig = {
  environmentName: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV === 'development',
  financeConfig: {
    defaultCurrency: (process.env.DEFAULT_CURRENCY ?? 'ILS') as Currency,
  },
  googleConfig: {
    credentials: {
      clientId: process.env.GOOGLE_CLIENT_ID,
    },
  },
}
