import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { globalConfig } from 'src/config/globalConfig'

const savedLanguage = localStorage.getItem('language') || 'en'

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        main: 'Main',
        homePage: 'Home',
        search: 'Search',
        employees: 'Employees',
        customers: 'Customers',
        downloadExcel: 'Download Excel',
        downloadReport: 'Download Report',
        downloadAllTimesheets: 'Download all timesheets',
        youCCSalaryReport: 'YouCC Salary Report',
        reportDate: 'Report Date',
      },
    },
    he: {
      translations: {
        main: 'ראשי',
        homePage: 'דף הבית',
        search: 'חיפוש',
        employees: 'עובדים',
        customers: 'לקוחות',
        downloadExcel: 'הורד קובץ אקסל',
        downloadReport: 'הורד דו"ח',
        downloadAllTimesheets: 'הורד דו"חות נוכחות',
        youCCSalaryReport: 'קובץ למשכורות יוסיסי',
        reportDate: 'תאריך דו"ח',
      },
    },
  },
  lng: savedLanguage,
  fallbackLng: 'en',
  debug: globalConfig.isDev,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
