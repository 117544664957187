import { ReactNode } from 'react'

import {
  Input,
  InputElementProps,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'

import i18n from 'src/i18n/i18n'

type CustomInputProps = InputProps & {
  inputGroupProps?: InputGroupProps
  startElement?: ReactNode
  startElementProps?: InputElementProps
  endElement?: ReactNode
  endElementProps?: InputElementProps
}

export const CustomInput = (props: CustomInputProps): ReactNode => {
  return (
    <InputGroup size={props.inputGroupProps?.size}>
      {props.startElement &&
        (i18n.dir() === 'ltr' ? (
          <InputLeftElement {...props.startElementProps}>
            {props.startElement}
          </InputLeftElement>
        ) : (
          <InputRightElement {...props.startElementProps}>
            {props.startElement}
          </InputRightElement>
        ))}
      <Input {...props} />
      {props.endElement &&
        (i18n.dir() === 'ltr' ? (
          <InputRightElement {...props.endElementProps}>
            {props.startElement}
          </InputRightElement>
        ) : (
          <InputLeftElement {...props.endElementProps}>
            {props.startElement}
          </InputLeftElement>
        ))}
    </InputGroup>
  )
}
