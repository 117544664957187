import { ReactNode } from 'react'

import { Flex } from '@chakra-ui/react'

export function AlignEnd({ children }: { children: ReactNode }): JSX.Element {
  return (
    <Flex w={'full'} justify={'end'}>
      {children}
    </Flex>
  )
}
