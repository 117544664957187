// This object will be used to override Chakra-UI theme defaults.
// See https://chakra-ui.com/docs/styled-system/theming/theme for theming options

export const colors = {
  brand: {
    100: '#e8edfa',
    200: '#a7b8ed',
    300: '#879de7',
    400: '#6683e0',
    500: '#355bd6',
    600: '#274abd',
    700: '#1d378d',
    800: '#162a6c',
    900: '#0c173b',
  },
}

export const components = {
  Table: {
    parts: ['th', 'td'],
    baseStyle: {
      // th: {
      //   borderColor: 'gray.900',
      // },
      // td: {
      //   borderColor: 'gray.300',
      // },
    },
  },
  Button: {
    baseStyle: {
      // bg: 'brand.900',
      // color: 'brand.100',
      // fontWeight: 'bold',
    },
  },
  Tabs: {
    variants: {
      'soft-rounded': {
        tab: {
          fontSize: '16px',
          bg: 'brand.100',
          _selected: {
            bg: 'brand.800',
            color: 'white',
          },
        },
      },
    },
    sizes: {
      md: {
        tab: {
          py: '3',
          px: '5',
          marginEnd: '3',
        },
        tabpanel: {
          pt: '6',
          pb: '8',
          px: '8',
        },
      },
    },
  },
}
