import { FaFileSignature } from '@react-icons/all-files/fa/FaFileSignature'
import { FaRegMoneyBillAlt } from '@react-icons/all-files/fa/FaRegMoneyBillAlt'
import { t } from 'i18next'
import { IconType } from 'react-icons'
import {
  FiActivity,
  FiBriefcase,
  FiFileText,
  FiHome,
  FiSettings,
  FiTool,
  FiTruck,
  FiUser,
  FiUsers,
} from 'react-icons/fi'

import { routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import SidebarWithHeader from 'src/components/SidebarWithHeader/SidebarWithHeader'

type MainLayoutProps = {
  children?: React.ReactNode
}

export interface LinkItemProps {
  name: string
  to?: string
  icon?: IconType
  defaultIsOpen?: true
  items?: LinkItemProps[]
}

export interface LinkItemGroup {
  name: string
  defaultIsOpen?: boolean
  items?: LinkItemProps[]
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { hasRole } = useAuth()

  let linkItemGroups: LinkItemGroup[] = [
    {
      name: t('main'),
      items: [
        { name: t('homePage'), to: routes.home(), icon: FiHome },
        { name: t('employees'), to: routes.employees(), icon: FiUsers },
        {
          name: 'Salaries Data',
          to: routes.employeeMonthlySalaries(),
          icon: FaRegMoneyBillAlt,
        },
        { name: 'Suppliers', to: routes.suppliers(), icon: FiTruck },
        {
          name: 'Reports',
          icon: FiActivity,
          defaultIsOpen: true,
          items: [
            { name: 'Work Hours Report', to: routes.workHoursReport() },
            {
              name: 'Salary Report',
              to: routes.employeesSalaryReport(),
            },
          ],
        },
        {
          name: 'Settings',
          icon: FiSettings,
          items: [
            { name: 'Currency Rates', to: routes.currencyRates() },
            { name: 'To Dos', to: routes.toDoDefinitions() },
          ],
        },
      ],
    },
  ]

  if (hasRole(['admin', 'finance'])) {
    linkItemGroups = [
      ...linkItemGroups,
      {
        name: 'Finance',
        items: [
          { name: t('customers'), to: routes.customers(), icon: FiBriefcase },
          {
            name: 'Open Invoices',
            to: routes.openInvoicesReport(),
            icon: FaRegMoneyBillAlt,
          },
          {
            name: 'Purchase Orders',
            to: routes.purchaseOrders(),
            icon: FaFileSignature,
          },
          {
            name: 'Invoices',
            icon: FiFileText,
            defaultIsOpen: true,
            items: [
              { name: 'Invoices Report', to: routes.invoicesReport() },
              {
                name: 'Invoices Tools',
                to: routes.invoicesTools(),
                icon: FiTool,
              },
              { name: 'Recent Invoices', to: routes.recentInvoices() },
            ],
          },
          {
            name: 'Reports',
            icon: FiActivity,
            defaultIsOpen: true,
            items: [
              { name: 'Projects Report', to: routes.projectsReport() },
              {
                name: 'Invoice Yearly Report',
                to: routes.invoiceYearlyReport(),
              },
            ],
          },
        ],
      },
    ]
  }

  if (hasRole('admin')) {
    linkItemGroups = [
      ...linkItemGroups,
      {
        name: 'Admin',
        items: [{ name: 'Users', to: routes.users(), icon: FiUser }],
      },
    ]
  }

  return (
    <SidebarWithHeader linkItemGroups={linkItemGroups}>
      {children}
    </SidebarWithHeader>
  )
}

export default MainLayout
