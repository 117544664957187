import { useEffect } from 'react'

import {
  Box,
  ChakraProvider,
  ColorModeScript,
  CSSReset,
  extendTheme,
} from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as theme from 'config/chakra.config'
import { I18nextProvider, useTranslation } from 'react-i18next'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import { globalConfig } from './config/globalConfig'
import i18n from './i18n'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './index.css'
import './scaffold.css'

const extendedTheme = extendTheme(theme)

const AppWrapper = ({ children }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'he' ? 'rtl' : 'ltr'
  }, [i18n.language])

  return (
    <Box>
      <CSSReset />
      {children}
    </Box>
  )
}

const App = () => {
  const googleClientId = globalConfig.googleConfig.credentials.clientId

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%AppTitle - %PageTitle">
        <GoogleOAuthProvider clientId={googleClientId}>
          <AuthProvider>
            <ColorModeScript />
            <ChakraProvider theme={extendedTheme}>
              <AppWrapper>
                <I18nextProvider i18n={i18n}>
                  <RedwoodApolloProvider useAuth={useAuth}>
                    <Routes />
                  </RedwoodApolloProvider>
                </I18nextProvider>
              </AppWrapper>
            </ChakraProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default App
