type LayoutProps = {
  title: string
  titleTo: string
  buttonLabel: string
  buttonTo: string
  children: React.ReactNode
}

const ScaffoldLayout = ({ children }: LayoutProps) => {
  return <>{children}</>
}

export default ScaffoldLayout
