import React, { useEffect } from 'react'

import {
  Button,
  Kbd,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'

import GlobalSearchModal from './GlobalSearchModal'

const GlobalSearch: React.FC = () => {
  const globalSearchModal = useDisclosure()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        globalSearchModal.onOpen()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [globalSearchModal])

  return (
    <>
      <Button
        size={'lg'}
        variant="outline"
        colorScheme="gray"
        borderColor={'gray.100'}
        boxShadow={'md'}
        color={'gray.600'}
        fontWeight={'normal'}
        minW={'600px'}
        justifyContent={'flex-start'}
        onClick={globalSearchModal.onOpen}
        leftIcon={<MdSearch size={'24px'} />}
      >
        <Stack direction="row" w="full">
          <Text>Search for everything</Text>
          <Spacer />
          <Stack direction="row" spacing={1}>
            <Kbd title="Command">⌘</Kbd>
            <Kbd title="K">K</Kbd>
          </Stack>
        </Stack>
      </Button>

      <GlobalSearchModal
        isOpen={globalSearchModal.isOpen}
        onClose={globalSearchModal.onClose}
      />
    </>
  )
}

export default GlobalSearch
