import React, { useMemo } from 'react'

import { Box, Stack, Text } from '@chakra-ui/react'
import { FiBriefcase, FiSearch, FiTruck, FiUsers } from 'react-icons/fi'
import { GlobalSearchResultItem } from 'types/graphql'

import { InactiveBadge } from '../Shared/InactiveBadge/InactiveBadge'

interface GlobalSearchResultItemBoxProps {
  item: GlobalSearchResultItem
  isSelected?: boolean
  onClick?: () => void
}

const GlobalSearchResultItemBox: React.FC<GlobalSearchResultItemBoxProps> = ({
  item,
  isSelected,
  onClick,
}) => {
  const { icon } = useMemo(() => {
    switch (item.type) {
      case 'Employee':
        return { icon: <FiUsers /> }
      case 'Customer':
        return { icon: <FiBriefcase /> }
      case 'Supplier':
        return { icon: <FiTruck /> }
      default:
        return { icon: <FiSearch /> }
    }
  }, [item])

  return (
    <Box
      as="button"
      width="100%"
      py={2}
      px={4}
      minHeight={16}
      borderRadius={'lg'}
      bg={isSelected ? 'blue.800' : 'gray.100'}
      color={isSelected ? 'white' : 'gray.800'}
      fontWeight={'semibold'}
      textAlign="left"
      _hover={{
        bg: 'blue.800',
        color: 'white',
      }}
      data-group
      onClick={onClick}
    >
      <Stack direction={'row'} spacing={4} alignItems={'center'}>
        <Box
          fontSize={'24px'}
          opacity={0.4}
          color={isSelected && 'white'}
          _groupHover={{ color: 'white' }}
        >
          {icon}
        </Box>
        <Stack spacing={0.5}>
          <Text>{item.title}</Text>
          {item.subtitle && (
            <Text
              fontSize={'xs'}
              color={isSelected ? 'gray.300' : 'gray.500'}
              _groupHover={{ color: 'gray.300' }}
            >
              {item.subtitle}
            </Text>
          )}
        </Stack>
        {item.isActive === false && <InactiveBadge />}
      </Stack>
    </Box>
  )
}

export default GlobalSearchResultItemBox
